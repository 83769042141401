.yiiLog {display: none;}

img {
  max-width: 100%;
}
p {
  line-height: 150%;
  margin: 0 0 10px;
}


a {
  color: $red;
}
a:hover {
  color: $red_hovered;
}

a:focus {
  outline: none;
  outline-offset: 0;
  text-decoration: none;
}

html {
  overflow-x: hidden;
}

body {
  background: none repeat scroll 0 0 $main_background;
  color: $grey_deep;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 13px;
  line-height: 110%;
}

#branding {
  margin-top: 20px;
}

body > #branding > .container-main {
  background: white;
}

.container-main {
  max-width: 1040px;
}

/*Горячие темы и курсы*/
#content-line {
  background: $main_background;
  color: $grey_mid;
  padding: 6px 10px;
  font-family: 'Open Sans Condensed',sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
#content-line a {
  color: black;
  text-transform: none;
  display: inline-block;
  margin-right: 5px;
}

/*Виджеты*/
.widget .header {
  background: $red;
  padding: 3px 10px;
  margin-bottom: 10px;
  margin: 0 -10px 10px -10px;
}
.widget .header h2 {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans Condensed',sans-serif;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;

}
.widget .header h2, .widget .header h2 a {
  color:white;
}
.b-light-gray {
  background: $grey_lightest;
}
.widget {
  padding: 0 10px 10px;
  margin-bottom: 10px;
}

.opinion-block img {
  width: 66px;
  display: block;
  margin-right: 7px;
  margin-bottom: 5px;
}

/*Главная новость*/
.first-news {
  padding: 0;
}
.first-news.no-padding .header {
  margin: 0 0 6px;
}

.first-news h1 {
  margin: 0;
  font-family: 'Open Sans Condensed',sans-serif;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}
.first-news h1 a {
  color:black;
}
.first-news h1 a:hover {
  text-decoration: none;
  color:$red;
}
.first-news p {
  line-height: 125%;
  font-size: 14px;
}
.first-news img {
  float: left;
  width:100%;
  display: block;
  margin: 0 10px 10px 0;
}


.first-news footer a, .opinion-block footer a {
  color:$grey_dark;
  text-decoration: underline;
}
.first-news footer a:hover, .opinion-block footer a:hover {
  color:$grey_dark
}
.first-news  footer , .opinion-block footer, .news-container footer, .fbottom {
  background: $grey_5;
  color:$grey_dark;
  font-size: 10px;
  padding: 5px 10px;

}



/*Поиск*/
.input-group {
  border-collapse: separate;
  display: table;
  position: relative;
}
.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.input-group-addon, .input-group-btn, .input-group .form-control {
  display: table-cell;
}
.input-group .form-control {
  float: left;
  margin-bottom: 0;
  position: relative;
  width: 100%;
  z-index: 2;
}
.form-control {
  background-color: white;
  background-image: none;
  border: 1px solid $grey_light;
  border-radius: 0px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #555555;
  display: block;
  font-size: 14px;
  height: 34px;
  line-height: 1.42857;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}
.input-group-btn {
  font-size: 0;
  position: relative;
  white-space: nowrap;
}

.input-group-addon, .input-group-btn {
  vertical-align: middle;
  white-space: nowrap;
  width: 1%;
}
.input-group-addon, .input-group-btn, .input-group .form-control {
  display: table-cell;
}
.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
  margin-left: -1px;
}
.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-radius: 0;
}

.form-control:focus {
  border-color: $red;
  box-shadow: none;
  outline: 0 none;
}


/*мнения*/
.opinion-block {
  padding: 5px 5px 0 5px;
  border:1px solid $grey_5;
  margin-bottom: 10px;
}
.opinion-block footer {
  margin: 0px -5px 0 -5px;
}
.opinion-block img {
  width: 64px;
  height: 64px;
}
.opinion-block h3 {
  margin: 0;
  font-family: 'Open Sans Condensed',sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: black;
  margin-bottom: 5px;
}
.opinion-blocka {
  color: black;
}
.opinion-block  a:hover h3  {
  color:$red;
  text-decoration: none;
}
.opinion-block  a:hover {
  text-decoration: none;
}
.blogfulltext a {
  color: $black_light;
  text-decoration: none;
}
.blogfulltext a:hover {
  text-decoration: underline;
}
.no-padding {
  padding: 0;
}
.no-padding .header {
  margin: 0 0 10px;
}
.a-right {
  text-align: right;
}

.nowrap {
  text-wrap: normal;
}

#back-top {
  color: $red;
  height: 60px;
  width: 70px;
  display: block;
  background: white;
  visibility: hidden;
  margin-left: -100px;
  position: fixed;
  text-align: center;
  z-index: 80;
  border-radius: 6%;
  opacity: 0.7;
  transition: 0.4s all;
  bottom: 180px;
  border: 3px solid $red;

  &:hover {
    opacity: 1;
    box-shadow: -1px 1px 19px -3px rgba(0,0,0,0.33);
  }

  i {
    line-height: 50px;
    font-size: 90px;
  }

  @include media-breakpoint-down(lg){
    margin: 0;
    bottom: 0;
    height: 25px;
    width: 100%;
    border-radius: 0;
    border: 0;
    left: 0;

    i {
      line-height: 25px;
      font-size: 40px;
    }
  }
}

.img-responsive,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
}
.img-rounded {
  border-radius: 6px;
}
.img-thumbnail {
  padding: 4px;
  line-height: 1.42857143;
  background-color: white;
  border: 1px solid $grey_3;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.img-circle {
  border-radius: 50%;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid $grey_8;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.carousel {
  position: relative;
}
.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.carousel-inner > .item {
  display: none;
  position: relative;
  -webkit-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}
.carousel-inner > .item > img {
  line-height: 1;
  height: 220px;
  width: 270;
}
.carousel-inner > .item {
  position: relative;
  margin-bottom: 20px;
}
.carousel-inner > .item > div {
  position: absolute;
  bottom: 0;
  background: rgba(0,0,0,0.8);
  padding: 10px;
  color:white;
}
.carousel-inner > .item > div.blogs-block {
  background: $grey_dark;
  position:absolute;
  width: 100%;
  bottom: -20px;

  padding: 5px 10px;
  margin: 0;
  color:white;
}
.carousel-inner > .item > div.blogs-block a {
  color:white;
}
.carousel-inner > .item > div h2 {
  margin: 0;
  padding: 0;
  font-size:14px; line-height: 125%;
  font-family: 'Open Sans Condensed',sans-serif;
  font-weight: bold;
}
.carousel-inner > .item > div h2 a {
  color:white;
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
  display: block;
}
.carousel-inner > .active {
  left: 0;
}
.carousel-inner > .next,
.carousel-inner > .prev {
  position: absolute;
  top: 0;
  width: 100%;
}
.carousel-inner > .next {
  left: 100%;
}
.carousel-inner > .prev {
  left: -100%;
}
.carousel-inner > .next.left,
.carousel-inner > .prev.right {
  left: 0;
}
.carousel-inner > .active.left {
  left: -100%;
}
.carousel-inner > .active.right {
  left: 100%;
}
.carousel-control {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-size: 20px;
  color: white;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.carousel-control.left {
  background-image: -webkit-linear-gradient(left, color-stop(rgba(0, 0, 0, 0.5) 0%), color-stop(rgba(0, 0, 0, 0.0001) 100%));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
}
.carousel-control.right {
  left: auto;
  right: 0;
  background-image: -webkit-linear-gradient(left, color-stop(rgba(0, 0, 0, 0.0001) 0%), color-stop(rgba(0, 0, 0, 0.5) 100%));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
}
.carousel-control:hover,
.carousel-control:focus {
  outline: none;
  color: white;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}
.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
  left: 50%;
}
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
  right: 50%;
}
.carousel-control .icon-prev,
.carousel-control .icon-next {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  font-family: serif;
}
.carousel-control .icon-prev:before {
  content: '\2039';
}
.carousel-control .icon-next:before {
  content: '\203a';
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  z-index: 15;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center;
  list-style: none outside none;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 15;
}
#carousel-example-generic .header {
  position: relative;
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  border: 1px solid white;
  border-radius: 10px;
  cursor: pointer;
  background-color: #000 \9;
  background-color: rgba(0, 0, 0, 0);
}
.carousel-indicators .active {
  margin: 0;
  width: 12px;
  height: 12px;
  background-color: white;
}
.carousel-caption {
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: white;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}
.carousel-caption .btn {
  text-shadow: none;
}

.carousel .header {
  margin: 0;
}

.red-button {
  background: $red;
  color:white;
  text-align: center;
  display:block;
  padding: 8px 10px;
  font-size:14px;
  font-family: 'Open Sans Condensed',sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
  border:none;
}
.red-button:hover, .red-button:focus, .red-button:active {
  color:white;
  background: #c30000;
  text-decoration: underline;
}
.small-btn {
  padding: 5px;
}
.gray-button {
  background: $grey_dark;
  color:white;
  text-align: center;
  display:block;
  padding: 8px 10px;
  font-size:14px;
  font-family: 'Open Sans Condensed',sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.gray-button:hover, .gray-button:focus, .gray-button:active {
  color:white;
  text-decoration: underline;
}
.gray-light-button {
  background: #f1f1f1;
  color:$grey_dark;
  border:1px solid #e2e2e2;
  text-align: center;
  display:block;
  padding: 8px 10px;
  font-size:14px;
  font-family: 'Open Sans Condensed',sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.gray-light-button:hover, .gray-light-button:focus, .gray-light-button:active {
  color:$grey_dark;
  background: #e9e9e9;
  text-decoration: none;
}

.gray-border-light {
  border:1px solid  $main_background;
}
.hr-bottom {
  margin-bottom: 40px;
}
.portlet-title {
  background: none repeat scroll 0 0 $red;
  line-height: 115%;
  padding: 3px 10px;
  color: white;
  font-family: 'Open Sans Condensed',sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
}

.portlet-title a{
  line-height: 115%;
  color: white;
  font-family: 'Open Sans Condensed',sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
}

.poll {
  padding-bottom: 5px;
}
.poll-widget-background {
  background: none repeat scroll 0 0 $grey_5;
  color: $grey_dark;
  font-size: 10px;
  padding: 5px 10px;
}

.poll-widget-background  a {
  color:$grey_dark; text-decoration: underline;
}
.poll-widget-background  a:hover {
  text-decoration:none;
}
.poll-widget-background  a.tocomments {
  text-decoration: none;
}

.block-commen-author {
  font-family: 'Open Sans Condensed',sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-bottom:5px;
}
.popular {
  /*font-size:12px;*/
}
.popular a {
  color: black;
}

.popular a:hover {
  color: $red;
  text-decoration:none;
}
.popular .created a:hover{
  color:$grey_dark;
  text-decoration: underline;
}
.popular .created a, .popular .created, #mostcreated .created {
  color:$grey_mid;
  font-weight: normal;
  font-size: 12px;
}

.comm-right {
  font-size:12px;
  display: block;
  margin-bottom: 5px;
}

a.popular-mini {
  font-size:12px;
}

#mostcomment a, #mostreading a {
  font-family: 'Open Sans Condensed',sans-serif;
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  line-height: 125%;
}

.quote {
  background-color: #CB3E3E;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05) inset;
  color: white;
  display: block;
  font-size: 100%;
  line-height: 120%;
  margin-bottom: 15px;
  margin-top: 15px;
  min-height: 15px;
  padding: 10px 15px;
}

.quote2:after {
  content: "»";
  font-size: 45px;
  color: $grey_4;
  position: absolute;
  right: 0;
  bottom: 0;
}
.quote2:before {
  content: "«";
  font-size: 45px;
  color: $grey_4;
  position: absolute;
  left: 0;
  top: 0;
}
.quote2, .redactor_editor p.quote2 {
  display: block;
  position: relative;
  color: $grey_4;
  font-size: 18px;
  font-style: italic;
  line-height: 129%;
  margin: 15px 0;
  padding: 0 35px;
}

.blogfulltext {
  font-size: 12px;
  margin-bottom: 5px;
}

.join-telegram {
  display: block;
  width: 100%;
  height: 37px;
  background: transparent url(/img/telegram.svg) no-repeat center;
  background-size: 25px;
  border-radius: 4px;
  background-color: #0088cc;
  margin: 10px 0;
}

h1.title {
  font-family: 'Open Sans Condensed',sans-serif;
  font-size: 27px;
  font-weight: bold;
  margin: 0 0 10px;
}
h3.introtext  {
  font-family: 'Open Sans Condensed',sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 15px;
}

.byline.author.vcard {
  text-align: right;
}

.authorbottom {
  color: #888888;
  font-style: italic;
  text-align: right;
}

.news-image-container {
  float: left;
  margin: 0 20px 20px 0;
  position: relative;
  text-align: center;
  width: 100%;
}
.news-image-container img {
  width: 100%;
}
.imgtitle {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0.6);
  bottom: 0;
  color: white;
  font-size: 13px;
  font-weight: bold;
  left: 0;
  line-height: 120%;
  padding: 4px 6px;
  position: absolute;
}

.close {
  color: #000000;
  float: right;
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  opacity: 0.2;
  text-shadow: 0 1px 0 white;
}
.modal-header .close {
  margin-top: 2px;
}

.no-margin {
  margin: 0;
}
.widget.carousel {
  padding: 0;
}

.main-news  {
  border-bottom: 1px solid $grey_light;
}

.red {
  color:$red;
}

.head-news h2 {
  font-family: 'Open Sans Condensed',sans-serif;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  line-height: 15x;
}

.head-news p {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 13px;
  line-height: 18px;
  margin: 0 0 9px;
}
#center h2.headtitle, #center h1.headtitle  {
  margin-top: 0;
  font-family: 'Open Sans Condensed',sans-serif;
  font-size: 28px;
  line-height: 125%;
  font-weight: bold;
  margin-bottom: 15px;
}
.news-container h2 {
  margin: 0;
  font-family: 'Open Sans Condensed',sans-serif;
  font-size: 18px;
  line-height: 125%;
  font-weight: bold;
  margin-bottom: 5px;
}
.news-container h2 a {
  color:black;
}
.news-container h2 a:hover {
  text-decoration: none;
  color:$red;
}

.img_container {
  max-width: 100%;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
}

.news-container {
  margin-bottom: 5px;
  border-top:1px solid $grey_8;
  padding-top: 5px;
}

.news-container p {
  margin-bottom: 0;
  line-height: 115%;
  font-size: 13px;
}

.pagination ul {
  display: inline-block;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-left: 0px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 1px 2px rgba(0,0,0,0.05);
}

.pagination li {
  display: inline;
}

.pagination li:first-child a {
  border-left-width: 1px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.pagination .disabled span, .pagination .disabled a, .pagination .disabled a:hover {
  color: $grey_4;
  cursor: default;
  background-color: transparent;
}

.pagination a {
  float: left;
  padding: 0 14px;
  line-height: 34px;
  text-decoration: none;
  border: 1px solid $grey_3;
  border-left-width: 0px;
}

.pagination .active a {
  color: $grey_4;
  cursor: default;
}

.item_news_info {
  text-align: right;
}

.comment {
  font-size: 11px;
  line-height: 15px;
  color: $black_light;
  margin: 0 0 15px 0;
  padding: 0;
}

.comment-header {
  margin-bottom: 5px;
  position: relative;
}

.comment-says {
  font-size: 115%;
  margin-bottom: 5px;
  padding-top: 10px;
}

.comment-links {
  color: $black_light;
}

.clr {
  clear: both;
}

.comment-inner {
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08);
  font-size: 13px;
  line-height: 17px;
  color: $black_dark;
  padding: 10px;
  border: 1px solid $grey_3;
  word-wrap: break-word;
  background: $grey_2;
}

.like-result {
  font-size:11px;
  font-weight: bold;
  display: inline-block;
  margin-right: 0;
  margin-left: 2px;
}
.likebutton, .dislikebutton, .newsLikebutton, .newsDislikebutton, .thumb-up, .thumb-down {
  display: inline-block;
  font-size: 11px;
  padding: 0 3px;
}
.likebutton, .dislikebutton, .newsLikebutton, .newsDislikebutton {
  color:$grey_light;
}
.likebutton:hover, .dislikebutton:hover, .newsLikebutton:hover, .newsDislikebutton:hover {
  text-decoration: none;
}

.red, .dislikebutton:hover, .newsDislikebutton:hover, .thumb-down {
  color:$red;
}
.green, .likebutton:hover, .newsLikebutton:hover, .thumb-up {
  color:$green;
}
.radio {
  padding: 5px 3px 5px 10px;
  margin: 5px 0 5px;
  border-bottom: 1px solid $grey_1;
}
.radio:last-child {
  margin-bottom: 0;
}
.radio:first-child {
  margin-top: 0;
}
.radio input[type="radio"] {
  margin-top: 2px;
  display: none;
}
.radio:hover {
  background: $grey_5;
  cursor: pointer;
}
.widget.poll {
  padding: 0;
}
.poll_r {
  float: left;
  width: 208px;
}
.result p {
  line-height: 115%;
  font-size: 13px;
  margin-bottom: 5px;
}
.result  {
  margin-bottom: 15px;
}
.votecircle {
  margin-top: 2px;
  display: block;
  float: left;
  margin-right: 5px;
  width: 12px;
  color: $grey_dark;
}

.padding {
  padding: 10px;
}


progress {
  background-color: $grey_6;
  background-image: -moz-linear-gradient(center top , $grey_7, $grey_lightest);
  background-repeat: repeat-x;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  height: 6px;
  margin-bottom: 5px;
  overflow: hidden;
}
.progress {
  background: $grey_8;
  margin-bottom: 6px;
}
.progress .bar {
  -moz-box-sizing: border-box;
  background-color: #0E90D2;
  background-image: -moz-linear-gradient(center top , #149BDF, #0480BE);
  background-repeat: repeat-x;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
  color: white;
  font-size: 12px;
  height: 3px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  transition: width 0.6s ease 0s;
  width: 0;
}
.progress .bar {
  background-color: $red;
  background-image: -moz-linear-gradient(center top , $red, #C10000);
}

.diagram {
  background-color: $grey_6;
  background-image: -moz-linear-gradient(center top , $grey_7, $grey_lightest);
  background-repeat: repeat-x;
  //border-radius: 4px;
  //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  height: 2px;
  margin-bottom: 5px;
  margin-top: 8px;
  overflow: hidden;
  padding: 0;
}

.diagram .bar {
  -moz-box-sizing: border-box;
  background-color: #0E90D2;
  background-repeat: repeat-x;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
  color: white;
  font-size: 12px;
  height: 18px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  transition: width 0.6s ease 0s;
  width: 0;
}
.diagram .progress-danger {
  background-color: $red;
  background-image: -moz-linear-gradient(center top , $red, #C10000);
  float:left;
}

.diagram .progress-success {
  background-color: green;
  //background-image: -moz-linear-gradient(center top , #00CA00, #00C100);
  float:left;
}


.diagram {
  background-color: $grey_6;
  background-image: -moz-linear-gradient(center top , $grey_7, $grey_lightest);
  background-repeat: repeat-x;
  //border-radius: 4px;
  //box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  height: 2px;
  margin-bottom: 5px;
  margin-top: 8px;
  overflow: hidden;
  padding: 0;
}

.diagram .bar {
  -moz-box-sizing: border-box;
  background-color: #0E90D2;
  background-repeat: repeat-x;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.15) inset;
  color: white;
  font-size: 12px;
  height: 18px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  transition: width 0.6s ease 0s;
  width: 0;
}
.diagram .progress-danger {
  background-color: $red;
  background-image: -moz-linear-gradient(center top , $red, #C10000);
  float:left;
}

.diagram .progress-success {
  background-color: green;
  //background-image: -moz-linear-gradient(center top , #00CA00, #00C100);
  float:left;
}

.well {
  background-color: #FAFAFA;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  min-height: 20px;
  padding: 10px;
}

input, textarea, select, .uneditable-input {
  background-color: white;
  border: 1px solid $grey_light;
  color: #555555;
  display: inline-block;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 9px;
  padding: 4px;
  width: 210px;
}
#reply-to {
  margin: 0;
}

.no-margin {
  margin: 0;
}

.fwnormal {
  font-weight: normal;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}
.alert h4 {
  margin-top: 0;
  color: inherit;
}
.alert .alert-link {
  font-weight: bold;
}
.alert > p,
.alert > ul {
  margin-bottom: 0;
}
.alert > p + p {
  margin-top: 5px;
}
.alert-dismissable {
  padding-right: 35px;
}
.alert-dismissable .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}
.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #2b542c;
}
.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}
.alert-info hr {
  border-top-color: #a6e1ec;
}
.alert-info .alert-link {
  color: #245269;
}
.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #66512c;
}
.alert-danger, .alert-error {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link, .alert-error .alert-link {
  color: #843534;
}

.fbottom {
  margin-bottom: 10px;
}

h1 {
  margin-top:0;
}

.head-news h2 {
  margin-bottom: 5px;
}
.head-news  {
  margin-bottom: 15px;
}

.first-news footer a.tocomments, .opinion-block footer a.tocomments, .tocomments, .main-news footer a.tocomments {
  text-decoration: none;
}
.tocomments:hover, .first-news footer a.tocomments:hover, .opinion-block footer a.tocomments:hover {
  color: $red;
}

.modal {
  display: none;
}

#sayOnline {
  display:  block;
  position: absolute;
  top: 0;
  right:0;
  padding: 3px 7px;
  color:white;
  background: $red;
  font-family: 'Open Sans Condensed',sans-serif;
  font-size: 14px;
  font-weight: bold;
}

#articleVotes {
  margin: 15px 0 15px;
}

#articleVotes a.newsLikebutton {
  color:green;
}
#articleVotes a.newsDislikebutton {
  color:$red;
}

.adminCommentBtns {
  background: none repeat scroll 0 0 $grey_8;
  bottom: -18px;
  font-size: 10px;
  padding: 0 8px;
  position: absolute;
  right: -1px;
}

.adminCommentBtns a, .adminCommentBtns span {
  margin-left: 15px;
}

.adminCommentBtns a:first-child {
  margin-left: 0px;
}

.main-news.official img {
  width: 100%;
}

.official .rontar {
  margin:-10px 0 10px -10px;
}
.breadcrumb {
  background-color: $grey_7;
  /*border-radius: 4px;*/
  list-style: none outside none;
  margin: 0 0 10px;
  /*padding-left: 0;*/
  padding: 8px 15px;
}
.breadcrumb > li {
  display: inline-block;
  text-shadow: 0 1px 0 white;
}
.breadcrumb > li > .divider {
  color: $grey_light;
  padding: 0 5px;
}

.form-horizontal .form-group {
  margin-left: 0;
  margin-right: 0;
}

.required {
  color:$red;
}

label.checkbox {
  font-weight: normal;
}
.help-block.error {
  color:$red;
  padding: 3px 0 ;
  margin: 0;
}
.main-news h3 a.bolded {
  color: black;
  font-family: "Open Sans Condensed",sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 5px;
}
#r_banner_container_8586 a {
  margin-bottom: 10px!important;
}
#rontar_adplace_9970 img, #rontar_adplace_9971 img {
  width: 223px!important;
  height: auto!important;
}

.mediamenu{
  position: relative;
  margin-bottom:30px;
}

.mediamenu.list{
  position: relative;
  padding: 7px 0 0 0;
  margin:0;
  clear:both;
}

.opinion-block .mediamenu.list{
  padding: 7px 0 0 0;
  text-align: right;
  position: absolute;
  right: 0;
  top: 8px;
}

footer .mediamenu.list {
  padding: 7px 0 7px 0;
  text-align: right;
}

.mediamenu a {
  vertical-align: bottom;
  width: 7em;
  height: 4em;
  line-height: 3.7em;
  margin: 0 1px 1px 0;
  padding: 3px 3px 3px 27px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: $black_light;
  display: inline-block;
  text-decoration: none !important;
  font-size: 0.8em;
}

.opinion-block .mediamenu.list a,
footer .mediamenu.list a{
  height: 12px;
  width: 15px;
  margin: 0 0 0 6px;
}

.mediamenu.list a {
  font-size: 1em;
  margin-bottom: 4px;
}

.mediamenu a {
  background: transparent url(/images/icons/read.png) no-repeat left center;
}

.mediamenu a.audio{
  background-image: url(/images/icons/audio.png);
  width:11em;
}

.mediamenu a.video{
  background-image: url(/images/icons/video.png);
  width: 13em;
}

.mediamenu a.download{
  background-image: url(/images/icons/download.png);
  width: 13em;
}

.mediamenu a:hover, .mediamenu a.current {
  background-image: url(/images/icons/read_r.png);
}

.mediamenu a.audio:hover, .mediamenu a.audio.current{
  background-image: url(/images/icons/audio_r.png);
}

.mediamenu a.video:hover, .mediamenu a.video.current{
  background-image: url(/images/icons/video_r.png);
}

.mediamenu a.download:hover, .mediamenu a.download.current{
  background-image: url(/images/icons/download_r.png);
}



.mediamenu a.current, .mediamenu a:hover{
  color: $red;
  text-decoration:none;
}

.mediamenu a.current{
  cursor:default;
}

.tabs .tab{
  display:none;
}

.tabs .tab.current{
  display:block;
}

.ivi_iframe html, .ivi_iframe body {overflow: hidden;}

.commercial {
  @include media-breakpoint-down(md){
    display: none;
  }
}

.commercial-mob {
  @include media-breakpoint-up(lg){
    display: none;
  }

  a {
    img {
      width: 100%;
    }
  }
}

#c6banner {

  z-index: 999;
  /*background: #ccc;*/
  margin-left: -250px;
  width: 513px;
  height: 500px;
  position: relative;
}
#c6banner.c6Fixed {
  position: fixed;
  top: 20px;
  width: 513px;
}

#rontar_adplace_5967 {
  position: absolute;
  top: 0px;
  padding:50px 0 0 0 !important;
}

.commentFormBlock {
  .well {
    width: 100%;
  }
}

@include media-breakpoint-up(lg){
  #top-adds {
    flex: 0 0 73.529%;
    max-width: 73.529%;
  }

  #left {
    flex: 0 0 24.805%;
    max-width: 24.805%;
  }

  #right {
    flex: 0 0 26.471%;
    max-width: 26.471%;
  }

  #center {
    flex: 0 0 48.724%;
    max-width: 48.724%;
  }
}

.row {
  margin-right: -5px;
  margin-left: -5px;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  padding-right: 5px;
  padding-left: 5px;
}

#right {
  text-align: center;
  div {
    text-align: left;
  }
}

.adsbygoogle {
  overflow: hidden !important;
}

.block-comment {
  img{
    width: 100%;
    height: auto !important;
  }
}

#hot {
  line-height: 20px;
  @include media-breakpoint-down(md) {
    padding-bottom: 15px;
  }
}

.commentContainer {
  .not-first {
    display: none;
  }
}

.show-comments-button {
  text-align: center;
  display: block;
  background: $red_header;
  padding: 7px 14px;
  color: white;
  transition: all 0.4s;

  &:hover {
    color: white;
    text-decoration: none;
    box-shadow: 0px 1px 7px 1px rgba(0, 0, 0, 0.5);
  }
}