#mainNav {
  position:relative;

  nav {
    padding: 0;

    .navbar-toggler {
      border: 1px solid white;
      margin: 4px;
      padding: 0.15rem 0.5rem;
      font-size: 1rem;
    }
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
      width: 1em;
      height: 1em;
    }

    ul.navbar-nav {
      &>li {
        &>a {
          font-family: 'Open Sans Condensed',sans-serif;
          font-size: 15px;
          font-weight: bold;
          color: white;
          text-transform: uppercase;
          display: block;
          width: 100%;
          margin: 0 0;
          padding: 8px 11px;
          height: 30px;
          text-align: center;
        }

        .dropdown-menu {
          left: unset;
          top: 94%;
          @include media-breakpoint-down(md){
            display: block;
          }

          &:before {
            border-bottom: 7px solid rgba(0, 0, 0, 0.2);
            border-left: 7px solid rgba(0, 0, 0, 0);
            border-right: 7px solid rgba(0, 0, 0, 0);
            content: "";
            display: inline-block;
            left: 9px;
            position: absolute;
            top: -7px;
          }

          &:after {
            border-bottom: 6px solid white;
            border-left: 6px solid rgba(0, 0, 0, 0);
            border-right: 6px solid rgba(0, 0, 0, 0);
            content: "";
            display: inline-block;
            left: 10px;
            position: absolute;
            top: -6px;
          }

          li {
            a {
              color: $grey_deep;
              font-size: 13px;
              display: block;
              padding: 7px 5px 7px 10px;
              white-space: nowrap;
            }
          }
        }

        .open .dropdown-menu {
          display: block;
        }

        &:hover {
          .dropdown-menu {
            display: block;
          }
        }
      }
    }
  }

  #socialLinks {
    position: absolute;
    right: 10px;
    top: 8px;
    list-style: none;
    font-size: 18px;

    @include media-breakpoint-down(md){
      top: 9px;
    }

    li {
      list-style: none;
      float: left;
      margin-left: 10px;

      a {
        color:white;
      }
    }
  }

  .right-links {
    font-family: 'Open Sans Condensed',sans-serif;
    font-size: 15px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    margin: 0 0;
  }
}

