body.statistics-page {
  font-family: 'Open Sans Condensed',sans-serif;

  .navbar {
    h1 {
      padding: 20px 30px 10px;
      text-align: center;
      color: white;
      font-size: 21px;
    }
  }
  .periods-holder {
    display: flex;
    flex-wrap: wrap;

    a {
      font-size: 18px;
      font-weight: normal;
      display: block;
      line-height: 40px;
      margin: 0 10px;
      padding: 10px;

      &:hover,
      &.active {
        text-decoration: none;
        background-color: #ffffff;
      }
    }
  }

  .input-daterange {
    display: flex;
    margin-top: 15px;

    .input-group-addon {
      width: auto;
      max-width: max-content;
      float: none;
      padding: 10px 20px;
      display: block;
    }

    input {
      height: 35px !important;
      max-width: 85px !important;
      width: auto !important;
      display: inline-block !important;
      float: none !important;
      font-size: 12px;
      font-weight: bold;
    }
  }

  .datepicker-dropdown {
    margin-top: 30px;

    .table-condensed {
      font-size: 12px;
    }
  }

  .main-stat {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px 40px;

    .main-stat-block {
      padding: 20px 40px;
      margin: 20px 0;
      text-align: center;
      border-radius: 8px;
      -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.6);
      box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.6);

      @include media-breakpoint-down(xs) {
        width: 100%;
        padding: 20px;
      }

      &.down, &.up {
        div {
          color: $red;

          &:before {
            margin-left: -17px;
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            content: "\f107";
            font-weight: normal;
          }
        }
      }

      &.up {
        div {
          color: green;
          &:before {
            content: "\f106";
          }
        }
      }

      div {
        font-size: 28px;
      }
    }
  }

  .banner-stat {
    .place-stat {
      hr {
        margin: 20px 0 12px 0;
      }

      .place-stat-wrapper {
        display: flex;
        padding-bottom: 20px;
        flex-wrap: wrap;

        .banners-stat {
          border-radius: 8px;
          -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.6);
          box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.6);
          padding: 8px;
          margin: 5px;

          &:first-child {
            margin-left: 0px;
          }

          &:last-child {
            margin-right: 0px;
          }

          .item-stat-wrapper {
            .item-stat-block {
              padding: 4px 0;
            }

            .stat-icon {
              padding: 4px 7px;

              &.up {
                color: green;
              }

              &.down {
                color: $red;
              }
            }
          }

          img {
            margin-top: 15px;
            border-radius: 6px;
            max-width: 200px;
          }
        }
      }
    }
  }

  #statistics-form {

    .form-row {
      display: flex;
      justify-content: center;

      div {
        font-size: 60px;
        line-height: 59px;
      }
    }

    input[type=submit] {
      font-size: 17px;
      margin-top: 18px;
    }

    input[type=text] {
      font-size: 42px;
      display: inline-block;
      border: none;
      padding: 5px;
      box-shadow: none;
      max-width: 180px !important;
      text-align: center;
      height: auto !important;
      font-weight: normal;

      &:active, &:focus {
        border-bottom: 1px solid $red;
        color: $red
      }
    }
  }
}