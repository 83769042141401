.topten-tabs-buttons {
  display: flex;
  justify-content: center;

  a {
    display: inline-block;
    color: $red;
    border: 1px solid $red;
    padding: 5px 8px;
    margin: 5px 4px 15px 4px;
    text-decoration: none;
    width: 100%;
    text-align: center;

    &.active {
      background: $red;
      color: white;
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.topten-tabs {
  .topten-tab {
    display: none;
    margin: 0;
    padding: 0 0 0 20px;

    &.active {
      display: block;
    }

    li {
      a {
        display: block;
        color: $black_light;
        padding-bottom: 12px;
        text-decoration: none;

        span {
          color: $red;
          white-space: nowrap;
          font-size: 11px;
        }
      }
    }
  }
}