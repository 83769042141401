.article-container {
  .fulltext {
    font-size:16px;

    img {
      height:auto;
    }

    ul, ol {
      li {
        padding-bottom: 10px;
        line-height: 19px;
      }
    }

    iframe {
      width: 100% !important;

      @include media-breakpoint-down(md){
        height: 320px;
      }

      @include media-breakpoint-down(sm){
        height: 180px;
      }
    }
  }
}