header.main {
  margin-top: 30px;
  margin-bottom: 10px;

  #authorizing {
    position: absolute;
    left: 24px;
    font-size: 12px;
    margin-top: -24px;
    a {
      font-size: 12px;
      padding: 1px 7px;
    }
  }

  nav {
    background: $red;
    background: $red_header;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg0MDIwMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNjYTAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  $red_header 0%, $red 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$red_header), color-stop(100%,$red));
    background: -webkit-linear-gradient(top,  $red_header 0%,$red 100%);
    background: -o-linear-gradient(top,  $red_header 0%,$red 100%);
    background: -ms-linear-gradient(top,  $red_header 0%,$red 100%);
    background: linear-gradient(to bottom,  $red_header 0%,$red 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$red_header', endColorstr='$red',GradientType=0 );

    i.fa-angle-double-down {
      font-weight: bold;
    }

    & > ul {
      margin: 0;
      padding: 0;
      display: flex;

      & > li {
        list-style: none;
        padding: 0;
        height: 40px;

        &.active {
          background: $red_header_nav_1;
          background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZiMGUwZSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNhZTE1MTUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
          background: -moz-linear-gradient(top,  $red_header_nav_1 0%, $red_header_nav_2 100%);
          background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$red_header_nav_1), color-stop(100%,$red_header_nav_2));
          background: -webkit-linear-gradient(top,  $red_header_nav_1 0%,$red_header_nav_2 100%);
          background: -o-linear-gradient(top,  $red_header_nav_1 0%,$red_header_nav_2 100%);
          background: -ms-linear-gradient(top,  $red_header_nav_1 0%,$red_header_nav_2 100%);
          background: linear-gradient(to bottom,  $red_header_nav_1 0%,$red_header_nav_2 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$red_header_nav_1', endColorstr='$red_header_nav_2',GradientType=0 );

        }

        & > a {
          font-family: 'Open Sans Condensed',sans-serif;
          font-size: 15px;
          font-weight: bold;
          color:white;
          text-transform: uppercase;
          display: block;
          width: 100%;
          margin: 0 0;
          padding:  12px 11px;
          height: 40px;
          text-align: center;

          &:hover, &:focus{
            color:$grey_8;
            text-decoration: none;
            border: none;
          }
        }

        & > ul {
          position: absolute;
          background: white;
          margin: 0;
          padding: 0;

          & > li {
            border-top: 1px solid white;
            list-style: none;
            &.active {
              background:$red;
              text-decoration: none;

              a {
                color:white;

                &:hover {
                  color:white;
                }
              }
            }
            & > a {
              color:$black_light;
              font-size: 13px;
              display: block;
              padding: 7px 5px 7px 10px;
              white-space: nowrap;

              &:hover  {
                color:white;
                text-decoration: none;
                background: $red;
              }
            }
          }
        }
      }
    }
  }
}

header nav > ul > li:hover ul.dropdown-menu {
  display:block;
}

#ageWarning {
  background: none repeat scroll 0 0 $red;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  color: white;
  font-size: 11px;
  font-weight: bold;
  height: 24px;
  padding-top: 5px;
  position: absolute;
  right: -10px;
  text-align: center;
  top: -10px;
  width: 24px;
  z-index: 10;

  @include media-breakpoint-down(md){
    right: 8px;
  }
}

.mob-form-wrapper {
  display: none;
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.8);
  right: 35px;
  left: 35px;
  padding: 10px;
  margin-top: 12px;
  background: white;
  z-index: 2;

  &:before {
    position: absolute;
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
    top: -8px;
    right: 28px;
  }

  @include media-breakpoint-down(md){
    right: 15px;
    left: 15px;
  }

  .input-group-btn {
    text-align: end;

    button {
      color: white;
      background-color: $red;
      font-weight: bold;
      font-size: 12px;
    }
  }
}