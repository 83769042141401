#search {

  @include media-breakpoint-down(md){
    display: none;
  }

  margin-bottom: 10px;

  .input-group-btn {
    .btn {
      background-color: $red;
      color:white;
      padding: 0;
      border: 1px solid $red;
      height: 30px;
      width: 30px;
    }
  }

  #searchForm {
    #SearchForm_text {
      height: 30px;
      display: block;
      width: calc(100% - 30px );
    }
  }
}

.article-right {
  #search {
    margin-top: -40px;
  }
}