.opinion-block {
  header {
    img {
      float: left;
    }

    min-height: 70px;
  }


  .mediamenu {
    &.list {
      a span{
        text-indent:-9999px;
        display:none;
      }

      a.download:hover{
        background-image: url(/images/icons/download_s_r.png);
      }

      a.video:hover{
        background-image: url(/images/icons/video_s_r.png);
      }

      a.audio:hover{
        background-image: url(/images/icons/audio_s_r.png);
      }

      a:hover{
        background-image: url(/images/icons/read_s_r.png);
      }

      a.download{
        background-image: url(/images/icons/download_s.png);
      }

      a.video{
        background-image: url(/images/icons/video_s.png);
      }

      a.audio{
        background-image: url(/images/icons/audio_s.png);
      }

      a {
        background: transparent url(/images/icons/read_s.png) no-repeat center center;
        padding: 3px;
      }
    }
  }
}