.main-news {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      margin: 0;
      padding: 0;

      margin-bottom: 5px;
      border-bottom: 1px solid $grey_mid_light;
      margin-top: 7px;
      padding-bottom: 5px;

      header {
        display: flex;
        & > a {
          display: block;
          width: 66px;
          margin-right: 7px;
          margin-bottom: 5px;

          img {
            width: 100%;
          }
        }
      }

      h3 {
        @include make-col(8);
        font-family: Arial;
        font-size: 12px;
        margin: 0;
        margin-bottom: 2px;
        font-weight: bold;
        display: block;

        a {
          color:$black_light;
          font-weight: normal;
        }
      }

      h4 {
        font-size: 13px;
        font-weight: normal;
        margin: 0;
        margin-bottom: 2px;

        a {
          color:$black_light;

          &:hover {
            color:$red;
            text-decoration: none;
          }
        }
      }

      footer {
        font-size: 11px;
        color: $grey_mid_dark;

        a {
          color: $grey_mid_dark;
          text-decoration: underline;

          &:hover {
            color:$grey_dark
          }
        }
      }

      .footer {
        background: $grey_dark;
        color:white;
        margin: 0 -10px -10px -10px;
        padding: 5px 10px;
        text-align: center;

        a {
          color:white;
          text-transform: uppercase;
          font-family: 'Open Sans Condensed',sans-serif;
          font-size: 14px;
          font-weight: bold;
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.official {
    ul {
      margin-top: 20px;

      li {
        header {
          display: block;
        }
      }
    }
  }
}