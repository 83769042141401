#top-blogs {
  height: 70px;
  overflow: hidden;
  @include media-breakpoint-down(md){
    display: none;
  }

  .author {
    font-family: 'Open Sans Condensed',sans-serif;
    font-size: 12px;
    font-weight: bold;
    color: $grey;
    margin-bottom: 2px;
  }

  a {
    color: black;
    text-decoration: none;
    display: block;

    &:hover {
      color: $red;
    }
  }

  h2 {
    font-family: 'Open Sans Condensed',sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 2px;
    padding: 0;
  }

  img {
    float: left;
    height: 70px;
    display: block;
    margin-right: 5px;
  }

  .stat {
    font-size: 11px;
    color: $grey_light;
  }
}