.price-page {
  color: $red;
  line-height: normal;
  padding-bottom: $spacer*3;

  .line {
    display: block;
    margin: $spacer 0;
    height: 2px;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(202,0,0,1) 50%, rgba(255,255,255,0) 100%);
  }

  .title {
    margin-top: $spacer*3;
    margin-bottom: $spacer;
    text-align: center;
    font-weight: bold;
    color: $red;
    font-size: 48px;
  }

  .sub-title {
    margin-top: $spacer*2;
    margin-bottom: $spacer;
    color: $grey_blue;
    font-size: 28px;
    text-align: center;
  }

  .anchor-link {
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: $grey_blue;

    &:hover {
      color: $red;
      text-decoration: underline;
    }
  }

  .anchor-block {
    text-align: center;
  }

  .main-banner {
    background: url("/images/price-bg.png") no-repeat;
    background-size: cover;
    padding: 24px;
    background-color: $red;
    color: white;
    text-align: center;

    .main-text {
      margin-top: $spacer*3;
      font-size: 48px;
      font-weight: bold;
    }
    .secondary-text {
      margin-top: $spacer*2;
      font-size: 24px;
    }
    .phone {
      margin-top: $spacer*2;
      font-size: 20px;
    }
  }

  .views-block {
    padding: 24px;

    .view-block {
      text-align: center;
      border-left: 1px solid $red;

      &:first-child {
        border-left: none;
      }

      .view-block-count {
        font-size: 26px;
        line-height: normal;
        color: $grey_blue;

        span {
          color: $red;
          font-weight: bold;
          font-size: 54px;
        }
      }

      .view-block-text {
        color: $grey_blue;
        font-size: 20px;
        line-height: normal;
      }
    }
  }

  .info-text {
    margin-top: $spacer*3;
    font-size: 20px;
    color: $grey_blue;

    .time-block {
      background: url("/images/time.png") no-repeat;
      padding: $spacer;
      padding-left: 200px;

      span {
        font-size: 24px;
        color: $red;
      }
    }
  }


  .banner-col {
    text-align: center;
    color: $red;

    img {
      width: 100%;
      display: block;
    }
  }

  .add-types {
    margin-bottom: $spacer*3;
  }

  .price-table {
    background-color: $red;
    padding: $spacer;

    table {
      color: white;
      border-color: white;

      td, th {
        padding: $spacer;
        width: 20%;
        font-size: 20px;

        &:last-child {
          width: 40%;
        }
      }

      th {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }

  .contacts {
    font-size: 28px;
    padding: 0 $spacer*2 $spacer*2;

    .phone {
      font-size: 34px;
      text-align: center;
    }
  }
}